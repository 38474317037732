<template>
  <div id="productDetail">
    <section class="product flex mb40">
      <div class="leftImg mr30">
        <div class="bigImg mb20">
          <el-image fit="cover" :src="logo" style="width: 360px" />
        </div>
        <div class="littleImg">
          <template v-for="(item, index) in productPictureList">
            <div
              :key="index"
              :class="['imgItem', currLogo == index ? 'activeLogo' : '']"
              @click="chooseLogo(item.url, index)"
            >
              <el-image fit="cover" :src="item.url" style="width: 59px; height: 59px" />
            </div>
          </template>
        </div>
      </div>
      <div class="rightText flex flex-column text-left">
        <div class="f26 mb80">{{ productName }}</div>
        <div class="flex mb10">
          <div class="f16 mr30">价格</div>
          <div class="f18 text-red">
            {{ productNormList.length > 0 ? productNormList[typeIndex].price : price }}
          </div>
        </div>
        <div class="flex mb10">
          <div class="f16 mr30">销量</div>
          <div class="f18">
            {{ salesAmount }}
          </div>
        </div>
        <div class="flex mb10">
          <div class="f16 mr30 pt10">规格</div>
          <div class="flex flex-wrap align-center flex1">
            <template v-for="(item, index) in productNormList">
              <div
                class="spec mr10 f16 text-center"
                :class="[index == typeIndex ? 'activeSpec' : '']"
                :key="index"
                @click="change(index)"
              >
                {{ item.normName }}
              </div>
            </template>
          </div>
        </div>
        <div class="flex align-center mb10">
          <div class="f16 mr30">数量</div>
          <div class="flex align-center">
            <div class="num mr10">{{ num }}</div>
            <div class="flex flex-column mr10">
              <i class="el-icon-arrow-up activeIcon" style="font-size: 18px" @click="up" />
              <i class="el-icon-arrow-down activeIcon" style="font-size: 18px" @click="down" />
            </div>
            <div class="f16">件</div>
          </div>
        </div>
        <div class="flex align-center mb50">
          <div class="mr30"><span class="f16">运费：</span> {{ mailAmount }}</div>
          <div class="f16 mr30">{{ mail }}</div>
        </div>
        <div class="btnGroup flex align-center">
          <el-button class="btn1" @click="addCart()">加入购物车</el-button>
          <el-button class="btn2" @click="goOrder()">立即购买</el-button>
        </div>
      </div>
    </section>
    <div class="banner mb40">商品详情</div>
    <div class="richText">{{ productDetail }}></div>
    <div  v-for="(item, index) in productPictureList" style="margin-top:5%;">
      <div class="detailPicture">
        <el-image fit="cover" :src="item.url" style="width:100%; height:30%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "`<h3>富文本部分</h3>`",
      productName: "",
      price: 0,
      productNormList: [],
      productCode: "",
      logo: "",
      currLogo: 0,
      productDetail: "",
      typeIndex: 0,
      mail: "",
      mailAmount: 0,
      normName: "",
      normCode: "",
      num: 1,
      salesAmount: 0,
      cover: "",
      productPictureList: [],
    };
  },
  methods: {
    //
    up() {
      this.num++;
    },

    down() {
      if (this.num == 1) {
        this.$message({
          message: "数量最低选择一件！",
          type: "warning",
           offset:'400'
        });
      } else {
        this.num--;
      }
    },
    //添加购物车
    addCart() {
      var getData = {};
      getData.productCode = this.productCode;
      getData.price = this.productNormList.length > 0 ? this.productNormList[this.typeIndex].price : this.price;
      getData.productName = this.productName;
      getData.normName = this.productNormList[this.typeIndex].normName;
      getData.normCode = this.productNormList[this.typeIndex].normCode;
      getData.num = this.num;
      getData.logo = this.logo;
      getData.memberCode = this.memberCode;
      getData.productTypeName = this.productTypeName;
      getData.productTypeCode = this.productTypeCode;
      getData.mailAmount = this.mailAmount
      this.$api.notice.addCart(getData).then((res) => {
        if (res.data.code === 200) {
          // this.$message.success("添加成功");
           this.$message({
            message: "添加成功!",
            type: "success",
            offset:'400'
          });
        }
      });
      this.goCart();
    },
    //购物车页面
    goCart() {
      var getData = {};
      getData.productCode = this.productCode;
      getData.price = this.price;
      getData.productName = this.productName;
      getData.normName = this.normName;
      getData.normNo = this.normNo;
      getData.num = this.num;
      setTimeout(()=>{
		//需要延迟的代码 :0.5秒后延迟跳转到首页，可以加提示什么的
        this.$router.push({
            path:"/product/cart",
        });
        //延迟时间：0.5秒
    },500)
      // this.$router.push({
      //   path: "/product/cart",
      //   query: { detail: getData },
      // });
    },
    //支付页面
    goOrder() {
      var getData = {};
      getData.productCode = this.productCode;
      getData.price = this.productNormList.length > 0 ? this.productNormList[this.typeIndex].price : this.price + this.mailAmount;
      getData.productName = this.productName;
      getData.mailAmount = this.mailAmount
      getData.normCode = this.productNormList[this.typeIndex].normCode;
      getData.num = this.num;
      getData.logo = this.logo;
      getData.normName = this.productNormList[this.typeIndex].normName;
      getData.memberCode = this.memberCode;
      var tempList = []
      tempList.push(getData)
      
       let a=JSON.stringify(tempList)
      this.$router.push({
        path: "/product/payOrder",
        query: {
          productList: a,
          cart: 1,
        },
      });
    },
    //获取规格列表
    getTypeList() {
      var getData = {};
      getData.productCode = this.productCode;
      this.$api.notice.getTypeList(getData).then((res) => {
        if (res.data.code === 200) {
          this.productNormList = res.data.data;
        }
      });
    },
    getProductPictureList() {
      var getData = {};
      getData.productCode = this.productCode;
      this.$api.notice.ProductPictureList(getData).then((res) => {
        if (res.data.code === 200) {
          this.productPictureList = res.data.data;
        }
      });
    },

    queryDetail() {
      var getData = {};
      getData.productCode = this.productCode;
      this.$api.notice.queryDetail(getData).then((res) => {
        if (res.data.code === 200) {
          this.productName = res.data.data.productName;
          this.price = res.data.data.price;
          this.mailAmount = res.data.data.mailAmount;
          this.mail = res.data.data.mail == "0" ? "不包邮" : "包邮";
          this.logo = res.data.data.logo;
          this.productDetail = res.data.data.productDetail;
          this.salesAmount = res.data.data.salesAmount;
          this.getTypeList();
          this.getProductPictureList();
        }
      });
    },
    change(index) {
      this.typeIndex = index;
    },
    chooseLogo(url, index) {
      this.logo = url;
      this.currLogo = index;
    },
  },

  created() {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this.productCode = this.$route.query.code;
    this.productName = this.$route.query.name;
    this.productTypeCode = this.$route.query.productTypeCode;
    this.productTypeName = this.$route.query.productTypeName;
    this.queryDetail();
  },
};
</script>

<style lang="less" scoped>
#productDetail {
  width: 840px;
  margin: 0 auto;
  padding: 30px 0;
}
.product {
  .leftImg {
    // width: 333px;
    .bigImg {
      width: 360px;
      height: 309px;
      overflow: hidden;
    }
    .littleImg {
      white-space: nowrap;
      overflow-x: auto;
      width: 360px;
      .imgItem {
        display: inline-block;
        border: 1px solid gray;
        margin-right: 5px;
        padding: 3px;
        width: 67px;
        height: 67px;
      }
      .imgItem:hover{
        cursor: pointer;
      }
      .activeLogo {
        border: 1px solid red;
      }
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background-color: skyblue;
        background-image: -webkit-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
        );
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #ededed;
        border-radius: 10px;
      }
    }
  }
  .rightText {
    .spec {
      min-width: 55px;
      padding: 3px 10px;
      margin-bottom: 10px;
      border: 2px solid #b2acac;
      text-align: center;
    }
    .spec:hover{
      cursor: pointer;
    }
    .activeSpec {
      border-color: red;
    }
    .num {
      min-width: 55px;
      padding: 0 10px;
      font-size: 18px;
      // line-height: 33px;
      text-align: center;
      border: 2px solid #b2acac;
    }
    .activeIcon {
      transition: all 0.2s;
    }
    .activeIcon:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }
  .btnGroup {
    margin-top: auto;
    .btn1 {
      width: 129px;
      font-size: 16px;
      color: red;
      border: 2px solid red;
    }
    .btn2 {
      width: 129px;
      font-size: 16px;
      color: white;
      border: 2px solid red;
      background: red;
    }
  }
}
.banner {
  width: 100%;
  height: 54px;
  color: #f8b229;
  line-height: 50px;
  font-size: 24px;
  border-radius: 5px;
  border: 2px solid#F8B229;
}
.richText{
  font-size: 12pt;
}
.detailPicture{
  margin-top: -5%;
}
</style>
